import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Dashboard2 from './components/Dashboard2';
import CustomLayout from './components/Layout';
import Login from './components/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <CustomLayout>
            <Dashboard />
          </CustomLayout>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={
          <CustomLayout>
            <Dashboard />
          </CustomLayout>
        } />
        <Route path="/dashboard2" element={
          <CustomLayout>
            <Dashboard2 />
          </CustomLayout>
        } />
      </Routes>
    </Router>
  );
}

export default App;
