// components/Layout.js
import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;

function CustomLayout({ children }) {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <Sider width={200} theme="dark"> */}
        <Menu mode="horizontal" theme="dark" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            <Link to="/dashboard">E-QUAT</Link>
          </Menu.Item>
          {/* <Menu.Item key="2">
            <Link to="/dashboard2">Dashboard2</Link>
          </Menu.Item> */}
          {/* Add more menu items as needed */}
        </Menu>
      {/* </Sider> */}
      <Layout>
        <Content style={{ padding: '20px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default CustomLayout;
