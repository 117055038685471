import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Flex, Progress } from 'antd';
import axios from 'axios';

const Dashboard = () => {
  const [stations, setStations] = useState([]);
  const [soc, setSoc] = useState([]);
  const [soc101a, setSoc101a] = useState(null);
  const [soc101b, setSoc101b] = useState(null);
  const [soc505a, setSoc505a] = useState(null);
  const [soc505b, setSoc505b] = useState(null);
  const [soc103a, setSoc103a] = useState(null);
  const [soc103b, setSoc103b] = useState(null);

  useEffect(() => {
    // Function to fetch data from your API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://e-quat.kz/api/test/get-chargepoint-list');
        response.data.forEach((element) => {
          if (element.connectorStatus == "Available") {
            setSoc101a(null);
            setSoc101b(null);
            setSoc505a(null);
            setSoc505b(null);
            setSoc103a(null);
            setSoc103b(null);
            element.connectorStatus = "Свободный";
          } else if (element.connectorStatus == "Preparing") {
            element.connectorStatus = "Готов к зарядке";
          } else if (element.connectorStatus == "Charging") {
            element.connectorStatus = "Заряжается";
          }
        });
        setStations(response.data); // Set the fetched data to state
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();


    const getSocData = async () => {
      try {
        const response = await axios.get('https://e-quat.kz/api/test/get-soc-by-transaction');
        setSoc(response.data); // Assume the response data is an array
        response.data.forEach((element) => {
          if (element.chargePointSerialNumber=="10101") {
            if (element.connectorId=="1") {
              setSoc101a(element.value);
            } else {
              setSoc101b(element.value);
            }
          } else if (element.chargePointSerialNumber=="50505") {
            if (element.connectorId=="1") {
              setSoc505a(element.value);
            } else {
              setSoc505b(element.value);
            }
          } else if (element.chargePointSerialNumber=="103") {
            if (element.connectorId=="1") {
              setSoc103a(element.value);
            } else {
              setSoc103b(element.value);
            }
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getSocData();
    const intervalId2 = setInterval(fetchData, 30000);
    const intervalId = setInterval(getSocData, 30000); // Fetch data every 10 seconds

    return () => {clearInterval(intervalId); clearInterval(intervalId2); };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render


  function Soc101({ chargePoint, connector, value }) {
    if (chargePoint=="10101" && value != null) {
      return <Progress status="active" percent={value} />;
    }
    return null;
  }

  function Soc505({ chargePoint, connector, value }) {
    if (chargePoint=="50505" && value != null) {
      return <Progress status="active" percent={value} />;
    }
    return null;
  }

  function Soc103({ chargePoint, connector, value }) {
    if (chargePoint=="103" && value != null) {
      return <Progress status="active" percent={value} />;
    }
    return null;
  }


  function Item({ chargePoint, connector, status }) {
    if (chargePoint == "10101") {
      return (
        <Col span={12}>
          <div className="item"> {connector == "1" ? "Кабель A (GB/T)" : "Кабель B (CCS2)"} ✔</div>
          <div className="item" style={{color: status == "Свободный" ? "GREEN" : "ORANGE"}}> {status}</div>  
          <Soc101 chargePoint={chargePoint}
                    connector={connector}
                    value={connector == "1" ? soc101a : soc101b}></Soc101>
            
        </Col>
      );
    }
    return null;
  }

  function Item2({ chargePoint, connector, status }) {
    if (chargePoint == "50505") {
      return (
        <Col span={12}>
          <div className="item"> {connector == "1" ? "Кабель A (GB/T)" : "Кабель B (GB/T)"} ✔</div>
          <div className="item" style={{color: status == "Свободный" ? "GREEN" : "ORANGE"}}> {status}</div>
          <Soc505 chargePoint={chargePoint}
                    connector={connector}
                    value={connector == "1" ? soc505a : soc505b}></Soc505>

        </Col>
      );
    }
    return null;
  }

  function Item3({ chargePoint, connector, status }) {
    if (chargePoint == "103") {
      return (
        <Col span={12}>
          <div className="item"> {connector == "1" ? "Кабель A (GB/T)" : "Кабель B (GB/T)"} ✔</div>
          <div className="item" style={{color: status == "Свободный" ? "GREEN" : "ORANGE"}}> {status}</div>
          <Soc103 chargePoint={chargePoint}
                    connector={connector}
                    value={connector == "1" ? soc103a : soc103b}></Soc103>

        </Col>
      );
    }
    return null;
  }

  return (
    <div >
      <p>В целях удобства наших клиентов мы рады сообщить о партнерстве с ALVA. 
      <a href="https://play.google.com/store/apps/details?id=kz.alvacharge"> Android </a> и 
      <a href="https://apps.apple.com/kz/app/alva/id6474004635"> IOS</a>
      </p>

      <Row gutter={24}>
        <Col  xs={24} md={12} order={1} style={{ marginBottom: '16px' }}>
          <Card bordered={false} style={{
              minHeight: 200,
            }}>
            <h2>ст. Хан Шатыр-1 (120кВт)</h2>
            <Row gutter={24}>
              {stations.map((listValue, index) => {
                return (
                  <Item chargePoint={listValue.chargePointSerialNumber}
                    connector={listValue.connectorId}
                    status={listValue.connectorStatus}>name</Item>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col  xs={24} md={12} order={1} style={{ marginBottom: '16px' }}>
          <Card bordered={false} 
          style={{
            minHeight: 200,
          }}>
            <h2>ст. Хан Шатыр-2 (120кВт)</h2>
            <Row gutter={24}>
              {stations.map((listValue, index) => {
                return (
                  <Item2 chargePoint={listValue.chargePointSerialNumber}
                    connector={listValue.connectorId}
                    status={listValue.connectorStatus}>name</Item2>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
      <Col  xs={24} md={12} order={1} style={{ marginBottom: '16px' }}>
          <Card bordered={false} 
          style={{
            minHeight: 200,
          }}>
            <h2>ст. Республикалық Бас Мешіт (120кВт)</h2>
            <Row gutter={24}>
              {stations.map((listValue, index) => {
                return (
                  <Item3 chargePoint={listValue.chargePointSerialNumber}
                    connector={listValue.connectorId}
                    status={listValue.connectorStatus}>name</Item3>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
