// actions/authActions.js
import axios from 'axios';

export const login = (credentials) => {
  return (dispatch) => {
    // Dispatch an action to indicate the start of the login request
    dispatch({ type: 'LOGIN_REQUEST_START' });

    // Make the API call using Axios
    axios.post('/api/login', credentials)
      .then((response) => {
        // Extract the JWT token from the response
        const { token } = response.data;

        // Store the token in your Redux store or local storage
        dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });

        // Dispatch an action to indicate the success of the login request
        dispatch({ type: 'LOGIN_REQUEST_SUCCESS' });
      })
      .catch((error) => {
        // Dispatch an action with the login error
        dispatch({ type: 'LOGIN_ERROR', payload: error });
        // Dispatch an action to indicate the failure of the login request
        dispatch({ type: 'LOGIN_REQUEST_FAILURE' });
      });
  };
};
