// components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import axios from 'axios';

// function Dashboard() {
//   return (
//     <div>
//       <h2>Dashboard</h2>
      
//     </div>
//   );
// }


const Dashboard2 = () => {
  const [data, setData] = useState([]);

  // Define the columns for your table
  const columns = [

    {
      title: 'Номер станции',
      dataIndex: 'chargePointSerialNumber',
      key: 'chargePointSerialNumber',
    },
    {
      title: 'Время обновления',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'Пистолет',
      dataIndex: 'connectorId',
      key: 'connectorId',
    },
    {
      title: 'Процент зарядки',
      dataIndex: 'value',
      key: 'value',
    },
    // Add more columns as needed
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://e-quat.kz/api/test/get-soc-by-transaction');
        setData(response.data); // Assume the response data is an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Fetch data every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []); // Empty dependency array means this effect runs once on mount

  return (
  <div>
    
    <p>В целях удобства наших клиентов мы рады сообщить о партнерстве с ALVA. 
      <a href="https://play.google.com/store/apps/details?id=kz.alvacharge"> Android </a> и 
      <a href="https://apps.apple.com/kz/app/alva/id6474004635"> IOS</a>
    </p>
    <Table columns={columns} dataSource={data} rowKey="transactionId" />
  </div>
  );
};

export default Dashboard2;
