// components/Login.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../actions/authActions';
import { Form, Input, Button, Alert } from 'antd';
//import 'antd/dist/antd.css'; // Import Ant Design styles


// components/Login.js
function Login() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loginError, setLoginError] = useState('');

  const handleLogin = async (values) => {
    try {
      await dispatch(login(values));
      // Redirect the user to the desired page upon successful login
    } catch (error) {
      setLoginError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {loginError && <Alert message={loginError} type="error" showIcon />}
      <Form
        form={form}
        onFinish={handleLogin}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
